/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/10/2023
 * @descriptionThe The `UsersTable` component renders a DataTable displaying user data. It includes search functionality, pagination, and sorting. Users can be viewed or edited through actions in the table. The component utilizes translation and navigation hooks for internationalization and routing.
 */
import {Column} from "primereact/column";
import {Link, useNavigate} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {useTranslation} from "react-i18next";
import {InputText} from "primereact/inputtext";

export default function UsersTable({data, onLazy, lazyTable, onSearch}) {
    const navigate = useNavigate();
    const {t} = useTranslation()

    const tableHeader = () => <div className={"row d-block d-lg-flex justify-content-between"}>
        <div className={"col-12 col-lg-4 mb-4 mb-lg-0"}>
            <div className={"b-inline-search"}>
                <InputText className={"b-inline-search__input"} style={{width: "100%"}}
                           placeholder={t("Search by nif, name, email or mobile")}
                           onChange={onSearch}/>

                <span className="b-inline-search__icon material-symbols-outlined">search</span>
            </div>
        </div>
        <div className={"col-12 col-lg-6 u-text-align-right"}>
            <Link to={"./new"}>
                <Button label={t("Create user")}></Button>
            </Link>
        </div>
    </div>


    const InsurancesActions = (rowData) => {
        const items = [{
            label: t("See"),
            icon: <span className="me-4 material-symbols-outlined">visibility</span>,
            command: () => navigate("/users/" + rowData.id)

        }, {
            label: t("Edit"),
            icon: <span className="me-4 material-symbols-outlined">edit</span>,
            command: () => navigate("/users/" + rowData.id + "/edit")
        }];
        return <ActionsOverlayMenu items={items}/>
    }


    return <DataTable
        emptyMessage={t("No results found")}
        header={tableHeader}
        value={data}
        filterDisplay="row"
        dataKey="id"
        paginator
        rows={lazyTable.rows}
        totalRecords={lazyTable.totalRecords}
        onPage={onLazy}
        onFilter={(e) => onLazy(e, true)}
        onSort={onLazy}
        lazy
        first={lazyTable.first}
        sortOrder={lazyTable.sortOrder}>
        <Column field="id"  filter filterPlaceholder={t("Search")} header={t("ID")}/>

        <Column field="name"  filter filterPlaceholder={t("Search")} header={t("Name")} body={(rowData) => <Link to={"/users/" + rowData.id}>{rowData.name}</Link>}/>

        <Column field="firstSurname"  filter filterPlaceholder={t("Search")}
                header={t("Last name")} body={(rowData) => <Link to={"/users/" + rowData.id}>{rowData.firstSurname} </Link>}/>

        {/* <Column field="secondSurname"  filter filterPlaceholder={t("Search")}
                header={t("secondSurname")} body={(rowData) => <Link
            to={"/users/" + rowData.id}>{rowData.secondSurname}</Link>}/> */}

        <Column field="nif"  filter filterPlaceholder={t("Search")} header={t("NIF")}/>

        <Column field="mobile"  filter filterPlaceholder={t("Search")}
                header={t("Mobile")} body={(rowData) => rowData.mobile}/>

        <Column field="email"  filter filterPlaceholder={t("Search")}
                header={t("Email")}/>

        <Column field="status"  filter filterPlaceholder={t("Search")}
                header={t("Status")} body={({status}) => t(status)}/>

        <Column header={t("Actions")} body={InsurancesActions}/>
    </DataTable>
}
