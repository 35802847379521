export const proposalStatusOptions = [
    {
        label: "NEW",
        value: "NEW"
    },   {
        label: "EXPORTING",
        value: "EXPORTING"
    },
    {
        label: "EXPORTING_ERROR",
        value: "EXPORTING_ERROR"
    },   {
        label: "STUDY_PENDING",
        value: "STUDY_PENDING"
    },
    {
        label: "OFFER_PENDING",
        value: "OFFER_PENDING"
    },   {
        label: "CANCELED",
        value: "CANCELED"
    },
    {
        label: "USER_PENDING",
        value: "USER_PENDING"
    },   {
        label: "ACCEPTED",
        value: "ACCEPTED"
    },
    {
        label: "POLICY_PENDING",
        value: "POLICY_PENDING"
    },   {
        label: "FINISHED",
        value: "FINISHED"
    },
]
