export const createFilterParamsUtil = (event, filterKey="filter", limit = 10) => {
    if (event) {
        let params = `?limit=${limit}&offset=${event.first}`;

        if (event.filters) {
            const filters = event.filters;
            for (const key in filters) {
                if (Object.hasOwnProperty.call(filters, key)) {
                    const element = filters[key];
                    if (element.value) {
                        let filterType;
                        switch (element.matchMode) {
                            case "equals":
                                filterType = "filter";
                                break;
                            case "contains":
                                filterType = "likeFilter";
                                break;
                            case "date":
                                filterType = "startsWithfilter";
                                break;
                            default:
                                filterType = filterKey; 
                        }
                        let value = element.value;

                        if ((key === "expirationDate" || key == "createdAt") && element.value instanceof Date) {
                            const year = element.value.getFullYear();
                            const month = String(element.value.getMonth() + 1).padStart(2, "0"); // Asegurar 2 dígitos
                            value = `${year}-${month}`; // Convertir a formato YYYY-MM
                            params += `&${filterType}[${key}]=${value}`;
                        }else{
                            params += `&${filterType}[${key}]=${element.value}`;
                        }

                    }
                }
            }
        }

        return params;
    } else {
        return ""
    }

}
