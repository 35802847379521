/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 10/10/2023
 * @description The `ProposalsTable` component is used to display a table of proposals. It includes functionalities such as filtering, sorting, pagination, and actions for viewing and editing proposals. The table header can include a button for creating new proposals, and the rows display various details about each proposal, such as ID, branch, reference, creation date, expiration date, status, and adviser. Users can click on links to view or edit individual proposals. Additionally, there is an option to select a proposal branch via a dropdown modal.
 */
import {Column} from "primereact/column";
import {Link, useNavigate} from "react-router-dom";
import {DataTable} from "primereact/datatable";
import ActionsOverlayMenu from "../ActionsOverlayMenu/ActionsOverlayMenu";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {formatDateUtil} from "../../utils/formatDate.util";
import {Dialog} from "primereact/dialog";
import BranchDropdown from "../BranchDropdown/BranchDropdown";
import { Calendar } from "primereact/calendar";
import { proposalStatusOptions } from "../../consts/proposalsStatus.const";
import FilterDropdownTemplate from "../../templates/FilterDropdownTemplate";

export default function ProposalsTable({
                                           data,
                                           onLazy,
                                           lazyTable,
                                           isCreationDisabled,
                                           tableHeader,
                                           isBranchNeeded = false
                                       }) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState()

    const ProposalsActions = (rowData) => {
        const items = [
            {
                label: t("See"),
                icon: (
                    <span className="me-4 material-symbols-outlined">visibility</span>
                ),
                command: () => navigate("/proposals/" + rowData.id),
            },
            {
                label: t("Edit"),
                icon: <span className="me-4 material-symbols-outlined">edit</span>,
                command: () => navigate("/proposals/" + rowData.id + "/edit"),
            },
        ];
        return <ActionsOverlayMenu items={items}/>;
    };

    const monthYearFilterTemplate = (options) => {
        return (
            <Calendar
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
                view="month"
                dateFormat="yy-mm"
                placeholder={t("Select Month and Year")}
                showButtonBar
            />
        );
    };

    const proposalTableHeader = tableHeader ? tableHeader : () => (
        <div className={"d-flex justify-content-end"}>
            {!isBranchNeeded && <Link to={"./proposals/new"}>
                <Button label={t("Create proposal")}></Button>
            </Link>}
            {isBranchNeeded && <Button label={t("Create proposal")} onClick={() => setIsModalVisible(true)}></Button>}
        </div>
    );


    return <><DataTable emptyMessage={t("No results found")}
                        header={isCreationDisabled ? null : proposalTableHeader}
                        value={data}
                        filterDisplay="row"
                        dataKey="id"
                        paginator
                        totalRecords={lazyTable.totalRecords}
                        rows={lazyTable.rows}
                        onPage={onLazy}
                        onFilter={onLazy}
                        onSort={onLazy}
                        lazy
                        first={lazyTable.first}
                        sortOrder={lazyTable.sortOrder}
    >
        <Column
            field="id"

            filter
            filterPlaceholder={t("Search")}
            header={t("ID")}
        />

        <Column
            field="policyType.name"

            filter
            filterMatchMode="contains"
            filterPlaceholder={t("Search")}
            header={t("Branch")}
        />

        <Column
            field="policyType.name"

            header={t("Reference")}
            body={(rowData) => <Link
                to={"/proposals/" + rowData.id}>{rowData.parentPolicyId && rowData.parentPolicy ? rowData.parentPolicy.referenceValue : t("Not avaible")}</Link>}
        />


        <Column
            field="createdAt"

            filter
            filterElement={monthYearFilterTemplate}
            filterMatchMode="contains"
            filterFunction={(value, filter) => {
                const valueMonthYear = value ? value.substring(0, 7) : "";
                const filterMonthYear = filter ? filter.toISOString().substring(0, 7) : "";
                return valueMonthYear === filterMonthYear;
            }}
            filterPlaceholder={t("Search")}
            body={({createdAt}) => formatDateUtil(createdAt, false, t)}
            header={t("Creation date")}
        />

        <Column
            field="expirationDate"

            filter
            filterElement={monthYearFilterTemplate}
            filterMatchMode="contains"
            filterFunction={(value, filter) => {
                const valueMonthYear = value ? value.substring(0, 7) : "";
                const filterMonthYear = filter ? filter.toISOString().substring(0, 7) : "";
                return valueMonthYear === filterMonthYear;
            }}
            filterPlaceholder={t("Search")}
            body={({expirationDate}) => formatDateUtil(expirationDate, false, t)}
            header={t("Expiration date")}
        />

        <Column
            field="status"

            filter
            filterMatchMode="equals"
            filterElement={(filter) => <FilterDropdownTemplate filter={filter} options={proposalStatusOptions}/>}
            filterPlaceholder={t("Search")}
            body={({status}) => t(status)}
            header={t("Status")}
        />

        <Column

            header={t("Adviser")}
            body={({user}) => user ? <Link
                to={"/users/" + user.id}>{user.advisor ? (user.advisor.firstName + " " + user.advisor.lastName) : ""}</Link> : ""}

        />


        <Column header={t("Actions")} style={{width: "160px"}} body={ProposalsActions}/>
    </DataTable>

        <Dialog header={t("Select proposal branch")} visible={isModalVisible}
                onHide={() => setIsModalVisible(false)}>
            <BranchDropdown/>
        </Dialog></>
}

