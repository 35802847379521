/**
 * @author TECHXONN (Abel Cabeza Román)
 * @created 17/05/2023
 * @descriptionThe The `UsersPage` component manages a list of users, allowing for lazy loading and global search functionality. It maintains state for the list of users, global search text, and lazy table configuration. The `onLazy` function handles lazy loading of user data based on pagination and sorting parameters, while `doGlobalSearch` handles global search by sending a request to fetch users based on the search text. The component renders a table displaying user data along with pagination controls and a search input field.
 */
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {API} from "../../queries/api";
import {createFilterParamsUtil} from "../../utils/createFilterParams.util";
import UsersTable from "../../components/UsersTable/UsersTable";
import {checkAndReturnCorrectLazyTableValues} from "../../utils/checkAndReturnCorrectLazyTableValues.util";
import DynamicFilter from "../../components/DynamicFilter/DynamicFilter";

export default function UsersPage() {
    const filters = ["DNI", "Teléfono", "Email", "Apellidos"];
    const [users, setUsers] = useState([])
    const [globalSearchText, setGlobalSearchText] = useState("")
    const {t} = useTranslation()

    const [lazyTable, setLazyTable] = useState({
        filters: {
            firstSurname: { value: null },
            name: { value: null }
        },
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });


    const onLazy = (event, isFiltering = false) => {
        const newLazyTable = checkAndReturnCorrectLazyTableValues(lazyTable, event);
        setLazyTable(newLazyTable);
        getLazyData(newLazyTable, isFiltering);
    };

    const getLazyData = async (event, isFiltering) => {
        const {data: {data: users, count}} = await API(
            "user/user/list" + createFilterParamsUtil(event, "likeFilter") + "&search=" + globalSearchText
        );
        setLazyTable({...event, first: isFiltering ? 0 : event.first, totalRecords: count})
        setUsers(users);
    };

    const doGlobalSearch = async (event) => {
        const {data: {data: users, count}} = await API(
            "user/user/list" + createFilterParamsUtil(lazyTable) + "&search=" + event.target.value
        );
        setLazyTable({...lazyTable, page: 1, first: 0, totalRecords: count})
        setUsers(users);
        setGlobalSearchText(event.target.value)
    }

    useEffect(() => {
        getLazyData(lazyTable);
    }, []);


    return (<div>
        <div className={"b-box"}>
            {/* <DynamicFilter availableFilters={filters}/> */}
            <h2>{t("Users list")} ({lazyTable.totalRecords})</h2>
            <UsersTable data={users} onLazy={(e, isFiltering) => onLazy(e, isFiltering)} lazyTable={lazyTable}
                        onSearch={doGlobalSearch}/>
        </div>

    </div>)
}

